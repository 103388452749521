import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Collapse } from 'reactstrap';
import logodarktext from '../../../assets/images/logo-text.png';
import { Link } from "react-router-dom";
const mapStateToProps = state => ({
	...state
});

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.expandLogo = this.expandLogo.bind(this);
		this.activeRoute.bind(this);
		this.state = {
			authentication: this.activeRoute("/authentication") !== "" ? true : false,
			uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
			samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
			dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
			iconsPages: this.activeRoute("/icons") !== "" ? true : false,
			formlayoutPages: this.activeRoute("/form-layouts") !== "" ? true : false,
			formpickerPages: this.activeRoute("/form-pickers") !== "" ? true : false,
		};
	}
	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		document.getElementById("logobg").classList.toggle("expand-logo");
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }

	render() {
		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.settings.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className="scroll-sidebar">
					<div className="sidebar-nav" >
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}

						<Nav id="sidebarnav">
							<Link
								to={`/`}

							>
								<span className="float-left">
									<img href="/" src={logodarktext} height="60px" className="float-left" width="190px">
									</img>
								</span>
							</Link>
							<br></br>
							{this.props.routes.filter(i => i.isMenu).map((prop, key) => {
								if (prop.redirect) {
									return null;
								}
								else if (prop.navlabel) {
									return (
										<li className="nav-small-cap" key={key}>
											<span className="hide-menu" style={{ fontSize: "17px", paddingLeft: "40px" }}><b>{prop.name} jyg</b></span>
										</li>
									);
								}
								else if (prop.collapse) {
									let firstdd = {};
									firstdd[prop["state"]] = !this.state[prop.state];
									return (
										/*--------------------------------------------------------------------------------*/
										/* Menus wiil be goes here                                                        */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + ' sidebar-item ' + prop.extra} key={key}>
											{prop.clickable ? (
											<Link to={`${prop.path}`} className={`sidebar-link ${this.state[prop.state] }`} onClick={() => this.setState(firstdd)}>
											<span className="hide-menu" style={{ fontSize: "17px", paddingLeft: "40px", display: prop.name === "spicedabr" ? "none" : "" }}>
											  <b>{prop.name}</b>
											</span>
										  </Link> ) : (
											<span data-toggle="collapse" className="sidebar-link " aria-expanded={this.state[prop.state]} onClick={() => this.setState(firstdd)}>
												<span className="hide-menu" style={{ fontSize: "17px", paddingLeft: "40px", display: prop.name === "spicedabr" ? "none" : "" }}><b>{prop.name}</b></span>
											</span>
										  )
											}
											{/*--------------------------------------------------------------------------------*/}
											{/* Sub-Menus wiil be goes here                                                    */}
											{/*--------------------------------------------------------------------------------*/}
											<Collapse className="first-level " style={{paddingLeft: this.props.width < 767 ? "40px": "0px", left: "0px"}}  isOpen={this.state[prop.state]} >
												<ul>
													{prop.child.map((prop, key) => {
														if (prop.redirect) return null;
														if (prop.collapse) {
															let seconddd = {};
															seconddd[prop["state"]] = !this.state[prop.state];
															return (
																<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																	<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(seconddd)}>
																		<span className="hide-menu" style={{ fontSize: "17px", paddingLeft: "40px" }}><b>{prop.name}</b></span>
																	</span>
																	{/*--------------------------------------------------------------------------------*/}
																	{/* Sub-Menus wiil be goes here                                                    */}
																	{/*--------------------------------------------------------------------------------*/}
																	<Collapse className="second-level" isOpen={this.state[prop.state]}>
																		<ul>
																			{prop.subchild.map((prop, key) => {
																				if (prop.redirect) return null;
																				return (
																					<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																						<NavLink to={prop.path} activeClassName="active" className="sidebar-link">
																							<span className="hide-menu">{/* style={{fontSize : "17px"}} {prop.name} */}fr</span>
																						</NavLink>
																					</li>
																				);
																			})}
																		</ul>
																	</Collapse>
																</li>
															);
														}
														return (
															/*--------------------------------------------------------------------------------*/
															/* Adding Sidebar Item                                                            */
															/*--------------------------------------------------------------------------------*/
															<>
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key} >
																	<NavLink to={prop.path} className="sidebar-link" activeClassName="active" >
																		<span className="hide-menu" style={{ fontSize: "17px"}}>{prop.name} </span>
																	</NavLink>
																</li>
															</>
														);
													})}
												</ul>
											</Collapse>
										</li>
									);
								}
								else {
									return (
										/*--------------------------------------------------------------------------------*/
										/* Adding Sidebar Item                                                            */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
											<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
												<span className="hide-menu" style={{ fontSize: "17px", paddingLeft: "40px" }}><b>{prop.name}</b></span>
											</NavLink>
										</li>
									);
								}
							})}
						</Nav>
					</div>
				</div>
			</aside>
		);
	}
}
export default connect(mapStateToProps)(Sidebar);
